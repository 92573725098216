#tabla-demandas td.img-wrapper {
	text-align: center;
}

#tabla-demandas img {
	max-width: 50px;
	max-height: 50px;
}

.contacto {
	padding: 8px 16px;
	background-color: var(--bs-tertiary);
	border-radius: 50px;
	margin-bottom: 5px;
	color: white;
	max-width: 300px;
	cursor: pointer;
}

.contacto img {
	max-width: 50px;
	margin-right: 10px;
}

.contacto-modal img {
	max-width: 100px;
}

.demanda-sector {
	font-size: 0.8em;
	color: var(--bs-secondary);
	margin-right: 8px;
}

.demanda-sector:not(:first-child) {
	border-left: 1px solid black;
	padding-left: 8px;
	line-height: 15px;
	display: inline-block;
}
