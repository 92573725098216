// Todos los overrides a las variables van acá
$primary: #F48634;
$secondary: #008da7;

@import "../node_modules/bootstrap/scss/functions"; 
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

// Si se quieren agregar colores adicionales para usar por ejemplo en variant=""
$custom-colors: (
	'tertiary': #989898
);

$theme-colors: map-merge($theme-colors, $custom-colors);

@import "../node_modules/bootstrap/scss/bootstrap";