.filtros-wrapper {
	padding: 10px;
	background-color: lightyellow;
}

.filtros-wrapper label {
	font-weight: bold;
}

.filtros-wrapper .filtros-icon {
	font-size: 24px;
	margin-top: 10px;
	margin-right: 15px;
	color: var(--bs-tertiary)
}