#tabla-aliados img {
	max-width: 50px;
	max-height: 50px;
}

.logo-img img {
	border: 1px dashed orangered;
	max-width: 400px;
}

.logo-actual img {
	max-width: 400px;
}

.logo-actual hr {
	color: orangered
}