#tabla-proyectos tr.con-demora {
	background-color: #f85252;
}

#tabla-proyectos td.img-wrapper {
	text-align: center;
}

#tabla-proyectos td.hito {
	cursor: pointer;
}

#tabla-proyectos img {
	max-width: 50px;
	max-height: 50px;
}

.apoyo {
	padding: 8px 16px;
	background-color: var(--bs-tertiary);
	border-radius: 50px;
	margin-bottom: 5px;
	color: white;
	max-width: 300px;
	cursor: pointer;
}

.apoyo img {
	max-width: 50px;
	margin-right: 10px;
}

.apoyo-modal img {
	max-width: 100px;
}