.rmsc {
	--rmsc-main: var(--bs-primary) !important;
	--rmsc-hover: #f1f3f5;
	--rmsc-selected: #e2e6ea;
	--rmsc-border: #ccc;
	--rmsc-gray: #aaa;
	--rmsc-bg: #fff;
	--rmsc-p: 10px;
	--rmsc-radius: 4px;
	--rmsc-h: 38px;
}

.rmsc.rmsc-sm {
	--rmsc-h: 31px;
}

.rmsc.rmsc-lg {
	--rmsc-h: 48px;
}